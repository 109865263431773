import axios from "../../../utility/api_service_status"

const deviceDetails = (item = false) => {
  return {
    id: item ? item.id : 0,
    ip: item ? item.ip : "",
    name: item ? item.name : "",
    status: item ? item.status : "",
    ping: item ? item.ping : "",
    type: item ? item.type : "",
  }
}

export const getServiceStatus = () => (dispatch) =>
new Promise(function(resolve, reject){
    dispatch({
      type: "GET_SERVICE_STATUS_SUCCESS",
      payload: false
    })
    
    axios.get('service-status')
    .then(response => {
      if (response.data.ResultCode===1) {
        let deviceList = Object.entries(response.data.Data).map((item, i) => {
	        console.log(item)
          return deviceDetails(item[1])
        })
        dispatch({
          type: "GET_SERVICE_STATUS_SUCCESS",
          payload: deviceList
        })
        resolve()
      } else {
        reject()
        throw(response);
      }
    })
    .catch(err => reject())
})

export const updateSocketStatus = status => {
  return dispatch => dispatch({ type: "UPDATE_SOCKET_STATUS", payload: status })
}

