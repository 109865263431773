import axios from "../../../utility/api"
import { history } from "../../../history"

export const changeRole = role => {
  return dispatch => dispatch({
    type: "CHANGE_ROLE",
    payload: { userRole: role }
  })
}

export const loginWithApi = user => (dispatch) =>
new Promise(function(resolve, reject){
    axios.post('oauth/v2/token', {
      username: user.email,
      password: user.password,
      client_id:process.env.REACT_APP_API_CLIENT_ID,
      client_secret:process.env.REACT_APP_API_CLIENT_SECRET,
      grant_type:"password",
    })
    .then(response => {
      if (response.data && response.data.access_token) {
        dispatch({
          type: "LOGIN_WITH_API",
          payload: { userRole: "admin", accessToken: response.data.access_token, username: user.email }
        })
        resolve()
      }else{
        reject()
      }
    })
    .catch(err => {
      reject()
      })
})

export const getAdminDetailsByUsername = (token,username) => (dispatch) =>
new Promise(function(resolve, reject){
    axios.defaults.headers.common = {'Authorization': 'Bearer ' + token}
    axios.get('api/store/username/'+ username).then(res => {
      if (res.data.ResultCode === 1) {
        dispatch({
          type: "GET_ROLE",
          payload: { role: res.data.Data.roles, assignedStore: res.data.Data.company, id: res.data.Data.id}
        });
        resolve()
      } else {
        reject()
      }
    }).catch(err => reject())
})

export const logoutWithApi = () => {
  return dispatch => {
    dispatch({ type: "LOGOUT_WITH_API" })
    history.push("/login")
  }
}
