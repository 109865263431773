import React from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { useLocation } from 'react-router-dom';
import cn from 'classnames';
import { connect } from "react-redux"
import { withTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux';
import { updateLockerStatus } from 'redux/actions/locations';
import { updateSocketStatus } from 'redux/actions/service_status';
import { getOrders } from 'redux/actions/orders';
import { getAlerts } from "redux/actions/alerts";
import { getLockers} from "redux/actions/users";
import { getUsers } from "redux/actions/users";
import { getTransactions } from "redux/actions/transactions"

export const Socket = ({ accessToken, showIconStatus }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const websocketUrl = process.env.REACT_APP_API_WS_URL + '/ws/location-status?access_token=' + accessToken;

  const { socketStatus } = useSelector((state) => state.service_status)

  const {
    readyState,
  } = useWebSocket(websocketUrl, {
    onOpen: () => changeSocketStatus(true, '::Socket Opened'),
    onClose: () => changeSocketStatus(false, '::Socket Closed'),
    onError: () => changeSocketStatus(false, '::Socket Error'),
    shouldReconnect: () => true,
    reconnectAttempts: 10,
    reconnectInterval: () => 60 * 1000, //60 seconds interval between each attemp
    onMessage: (e) => handleTrigger(e)
  })

  const connectionStatus = {
    [ReadyState.CONNECTING]: '::Connecting Socket',
    [ReadyState.OPEN]: '::Socket Opened',
    [ReadyState.CLOSING]: '::Closing Socket',
    [ReadyState.CLOSED]: '::Socket Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  const changeSocketStatus = (active, status) => {
    dispatch(updateSocketStatus(active))
  }

  const handleTrigger = (e) => {
    if (e.data && e.data.charAt(0) === '{') {
      const parsedMsg = JSON.parse(e.data);

      const { MsgType, Data } = parsedMsg;

      switch (MsgType) {
        case 'LockerUpdate':

          //If sequence to prevent some dispatch are deployed unnecessarily, if not on page where it needed
          if (pathname.includes('/locations/details')) {
            dispatch(updateLockerStatus(Data))
          }
          else if (pathname === '/orders') {
            dispatch(getOrders(accessToken, 500, false))
          }
          else if (pathname === '/alerts') {
            dispatch(getAlerts(accessToken, true))
          }
          else if (pathname.includes('/office-users/details')) {
            dispatch(getLockers(accessToken))
          }

          break;
        case 'UpdateTransactions':
          
          dispatch(getTransactions(accessToken))

          break;
        case 'RefreshUsers':

          if (pathname === '/office-users') {
            dispatch(getUsers(accessToken, false))
          }

          break;
        case 'UpdateCapacity':
          
          break;

        default:
          console.log(connectionStatus)
      }
    }
  }

  return (
    showIconStatus ?
      <div
        style={{ height: '15px', width: '15px', borderRadius: '15px', boxShadow: '0px 0px 1px 1px #000000' }}
        className={cn(
          'pulse',
          {
            'bg-danger': !socketStatus,
            'bg-success': socketStatus,
          }
        )}
      />
      :
      null
  )

};

const mapStateToProps = state => {
  return {
    accessToken: state.auth.login.accessToken,
  }
}

export default connect(mapStateToProps)(withTranslation('common')(Socket))

//export default Socket;