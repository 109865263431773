import axios from "../../../utility/api"

const details = (item = false) => {
  return {
    id: item ? item.id : 0,
    transactionID: item ? item.transactionID : false,
    reference: item ? item.reference : false,
    identifier: item ? item.identifier : false,
    amount: item ? item.amount : false,
    changeAt: item ? item.changeAt : false,
    paymentMethod: item ? item.paymentMethod : false,
    paymentStatus: item ? item.paymentStatus : false,
    transactionStatus: item ? item.transactionStatus : false,
    description: item ? item.description : false,
    user: item ? item.user : false,
    locker: item ? item.locker : false,
    logs: item ? item.logs : false,
    rentalInfo: item ? item.rentalInfo : false,
    type: item ? item.type : false,
    voucherCode: item ? item.voucherCode : false,
    time: item ? item.time : false,
  }
}

export const getTransactions = (token) => (dispatch) =>
  new Promise(function (resolve, reject) {
    axios.defaults.headers.common = { 'Authorization': 'Bearer ' + token }
    axios.get('api/marketplace/transaction/list')
      .then(res => {
        if (res.data.ResultCode === 1) {
          let list = res.data.Data.map((item, i) => {
            return details(item)
          })
          dispatch({
            type: "GET_TRANSACTIONS_LIST_SUCCESS",
            payload: list
          })
          resolve(res)
        } else {
          reject()
          throw (res);
        }
      })
      .catch(err => {
        reject()
      })
  })

export const getTransactionDetails = (token, id) => (dispatch) =>
  new Promise(function (resolve, reject) {
    axios.defaults.headers.common = { 'Authorization': 'Bearer ' + token }
    axios.get('api/marketplace/transaction/details/' + id)
      .then(res => {

        if (res.data.ResultCode === 1) {
          dispatch({
            type: "GET_TRANSACTION_DETAILS_SUCCESS",
            payload: details(res.data.Data)
          })
          resolve(res)
        } else {
          reject(res)
          throw (res);
        }
      })
      .catch(err => {
        reject()
      })
  })

export const setTransaction = (token, transaction) => (dispatch) =>
  new Promise(function (resolve, reject) {
    axios.defaults.headers.common = { 'Authorization': 'Bearer ' + token }
    axios.put('api/marketplace/transaction/edit/' + transaction.id, {
      description: transaction.description,
    })
      .then(res => {
        if (res.data.ResultCode === 1) {
          dispatch({
            type: "GET_TRANSACTION_DETAILS_SUCCESS",
            payload: details(res.data.Data)
          });
          resolve(res)
        } else {
          reject()
        }
      })
      .catch(err => {
        reject()
      })
  })

export const updateTransaction = (token, transaction) => (dispatch) =>
  new Promise(function (resolve, reject) {
    axios.defaults.headers.common = { 'Authorization': 'Bearer ' + token }
    axios.put('api/marketplace/transaction/update/' + transaction.id, {
      voucherCode: transaction.voucherCode,
      email: transaction.email,
      nif: transaction.nif,
      time: transaction.time,
    })
      .then(res => {
        if (res.data.ResultCode === 1) {
          dispatch({
            type: "GET_TRANSACTION_DETAILS_SUCCESS",
            payload: details(res.data.Data)
          });
          resolve(res)
        } else {
          reject()
        }
      })
      .catch(err => {
        reject()
      })
  })