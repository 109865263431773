import React from 'react';
import { connect } from "react-redux"
import {
  Row,
  Col,
  Button,
  Modal,
  ModalBody
} from "reactstrap"
import axios from "utility/api"
import { assignLocker } from "redux/actions/orders"
import { toast } from "react-toastify"
import { withTranslation } from 'react-i18next'
import 'assets/scss/components/barcode-scanner.scss'
import ModalSpinner from "components/@vuexy/spinner/Modal-spinner"
import { getDeliveryLockers } from "redux/actions/locations"
import { X } from 'react-feather'
import Locker from 'components/Locker';

class DeliverOrderModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      lockerSize: 'none',
      isSubmitting: false,
      deliveryPlaces: false,
      selected: false,
    }
    this.fetchDeliveryLockers = this.fetchDeliveryLockers.bind(this)
  }

  componentDidMount() {
    this.mounted = true;
    this.fetchDeliveryLockers()
  }

  componentWillUnmount() {
    // Set mounted to false when the component is about to unmount
    this.mounted = false;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.showDeliveryModal !== this.props.showDeliveryModal && this.props.showDeliveryModal) {
      this.fetchDeliveryLockers()
    }
  }

  fetchDeliveryLockers() {
    this.setState({ isSubmitting: true })
    this.props.dispatch(getDeliveryLockers(this.props.accessToken))
      .then((res) => {
        if (this.mounted) {
          this.setAvailablePlace(res.data.Data);
          this.setState({ isSubmitting: false, deliveryPlaces: res.data.Data.length > 0 ? res.data.Data : false });
        }
      })
      .catch((err) => {
        this.setState({ isSubmitting: false })
        toast.error(this.props.t("notifications.location.errorFetchingList"))
      })
  }

  setAvailablePlace(data) {
    let availableLocation = false;

    for (let index = 0; index < data.length; index++) {
      const item = data[index];

      if (item.availableLockers.length !== 0 && item.availableLockers) {

        availableLocation = item;
        break; // Exit the loop when location have available lockers
      }
    }

    this.setState({ selected: availableLocation });
  }

  setLockerSize = (lockerSize) => {
    this.setState({ lockerSize: lockerSize })
  }

  requestLocker = () => {
    if (this.state.lockerSize !== 'none') {
      this.setState({ isSubmitting: true })
      this.props.dispatch(assignLocker(this.props.accessToken, this.state.selected.id, this.props.selectedOrder.packageId, this.state.lockerSize, true)).then(() => {
        this.setState({ isSubmitting: false })
      }).catch((err) => {
        this.setState({ isSubmitting: false })
        if (err === 'No locker available') {
          toast.error(this.props.t("notifications.deliverOrder.noLockersAvailable"))
        } else {
          toast.error(this.props.t("notifications.deliverOrder.errorRequestingLocker"))
        }
        this.props.toggleDeliveryModal()
      })
    } else {
      toast.error(this.props.t("notifications.deliverOrder.selectLockerSize"))
    }
  }

  openDoorAndDeliver = () => {
    this.setState({ isSubmitting: true })
    axios.defaults.headers.common = { 'Authorization': 'Bearer ' + this.props.accessToken }
    axios.get('api/delivery/storePackage?packageId=' + this.props.selectedOrder.packageId)
      .then(res => {
        this.setState({ isSubmitting: false })
        if (res.data.ResultCode === 1) {
          toast.success(this.props.t("notifications.deliverOrder.doorOpenedSuccessfuly"))
          this.props.toggleDeliveryModal()
        } else {
          toast.error(this.props.t("notifications.deliverOrder.errorOpeningDoor"))
        }
      })
      .catch(error => {
        this.setState({ isSubmitting: false })
        toast.error(this.props.t("notifications.deliverOrder.errorOpeningDoor"))
      })
  }

  render() {
    const { t, selectedOrder } = this.props

    return (
      <Modal
        isOpen={this.props.showDeliveryModal}
        toggle={this.props.toggleDeliveryModal}
        className="modal-dialog-centered modal-lg"
      >
        <ModalBody className="px-3">
          <Row className="py-2">
            <Col xs="10" className="d-flex justify-content-start">
              <span style={{ fontSize: '1.3em', fontWeight: '500' }}>
                {t('barcodeScanner.depositOrder')}
              </span>
            </Col>
            <Col xs="2" className="d-flex justify-content-end">
              <X size={30} style={{ cursor: 'pointer' }} onClick={this.props.toggleDeliveryModal} />
            </Col>
          </Row>

          {!selectedOrder && <ModalSpinner />}

          {selectedOrder && this.state.deliveryPlaces ?
            <React.Fragment>
              <Row>
                <Col xs="12" lg="4" className="d-flex flex-column align-items-sm-center align-items-sm-center align-items-lg-start">
                  <span className="font-weight-semi-bold mb-1">
                    {t('orderDetails.packageId')}
                  </span>
                  <span className="pb-1">
                    {selectedOrder.packageId}
                  </span>
                </Col>
                <Col xs="12" lg="4" className="d-flex flex-column align-items-sm-center align-items-lg-center">
                  <span className="font-weight-semi-bold mb-1">
                    {t('orderDetails.recipientPhone')}
                  </span>
                  <span className="pb-1">
                    {selectedOrder.recipientPhone}
                  </span>
                </Col>
                <Col xs="12" lg="4" className="d-flex flex-column align-items-sm-center align-items-lg-end">
                  <span className="font-weight-semi-bold mb-1">
                    {t('orderDetails.recipientNumber')}
                  </span>
                  <span className="pb-1">
                    {selectedOrder.recipientNumber}
                  </span>
                </Col>
              </Row>

              {this.state.selected &&
                <Row xs="auto">
                  <Col xs="12" className="pt-1">
                    <span className="font-weight-semi-bold">
                      {t('activity.location')}
                    </span>
                  </Col>
                  {Object.keys(this.state.deliveryPlaces).map((key, index) =>
                    <Col
                      key={index}
                      className="d-flex justify-content-center align-items-center py-1"
                    >
                      <Button
                        block
                        color="primary"
                        onClick={() => this.setState({ selected: this.state.deliveryPlaces[key] })}
                        outline={this.state.selected.id !== this.state.deliveryPlaces[key].id}
                        disabled={this.state.deliveryPlaces[key].availableLockers.length === 0}
                      >
                        <span>
                          {this.state.deliveryPlaces[key].name}
                        </span>
                      </Button>
                    </Col>
                  )}
                </Row>
              }
              {this.state.selected &&
                <Row xs="auto" className="py-1">
                  <Col xs="12">
                    <span className="font-weight-semi-bold">
                      {t('orderDetails.size')}
                    </span>
                  </Col>
                  {Object.keys(this.state.selected.availableLockers).map((key, index) =>
                    <Col xs="6" lg="3" className="py-1" key={index}>
                      <Button
                        block
                        color="primary"
                        outline={this.state.lockerSize !== key}
                        onClick={() => this.setState({ lockerSize: key })}
                        className="d-flex flex-column justify-content-between align-items-center"
                      >
                        <span className="font-weight-bold" style={{ marginBottom: '5px' }}>
                          {key}
                        </span>
                        <span style={{ fontSize: '0.8em' }}>
                          {this.state.selected.availableLockers[key].length} {t("orders.available")}
                        </span>
                      </Button>
                    </Col>
                  )}
                </Row>
              }

              {!selectedOrder.assignedLocker.number &&
                <Row className="py-1">
                  <Col xs="12">
                    <Button disabled={!this.state.selected} color="primary" block onClick={() => this.requestLocker()}>
                      {t('barcodeScanner.requestLocker')}
                    </Button>
                  </Col>
                </Row>
              }

              {selectedOrder.assignedLocker.number &&
                <Row className="py-1">
                  <Col xs="2" s>
                    <Locker key={selectedOrder.assignedLocker.id} locker={selectedOrder.assignedLocker} clickCallback={() => { return null }} noPointer />
                  </Col>
                  <Col xs="4" className="d-flex flex-column justify-content-between py-1">
                    <Row>
                      <span className="font-weight-semi-bold mr-1">
                        {t('activity.location')}
                      </span>
                      <span>
                        {selectedOrder.assignedLocker.locationName}
                      </span>
                    </Row>
                    <Row>
                      <span className="font-weight-semi-bold mr-1">
                        {t('orderDetails.size')}
                      </span>
                      <span>
                        {selectedOrder.assignedLocker.size}
                      </span>
                    </Row>
                    <Row>
                      <span className="font-weight-semi-bold mr-1">
                        {t('orderDetails.lockerNumber')}
                      </span>
                      <span>
                        {selectedOrder.assignedLocker.doorNumber}
                      </span>
                    </Row>
                  </Col>
                  <Col xs="6">
                    <Button color="warning" className="mb-1" disabled={this.state.isSubmitting} block onClick={() => this.requestLocker()}>
                      {t('barcodeScanner.changeLocker')}
                    </Button>
                    <Button color="primary" disabled={this.state.isSubmitting} block onClick={() => this.openDoorAndDeliver()}>
                      {t('barcodeScanner.confirmAndDeliver')}
                    </Button>
                  </Col>
                </Row>
              }
            </React.Fragment>
            :
            <Row>
              <Col xs="12" className="d-flex justify-content-start pb-2">
                <span>
                  {t('notifications.deliverOrder.noLockersAvailableMoment')}
                </span>
              </Col>
            </Row>
          }
        </ModalBody>
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    accessToken: state.auth.login.accessToken,
    selectedOrder: state.orders.selectedOrder,
    lockersBySize: state.locations.lockersBySize
  }
}

export default connect(mapStateToProps)(withTranslation('common')(DeliverOrderModal))
