const serviceStatusReducer = (state = { serviceStatus : [], socketStatus: false }, action) => {
  switch (action.type) {
    case "GET_SERVICE_STATUS_SUCCESS":
      return { ...state, serviceStatus: action.payload }
      case "UPDATE_SOCKET_STATUS":
      return { ...state, socketStatus: action.payload }
    default:
      return state
  }
}

export default serviceStatusReducer
