export const login = (state = { userRole: "visitor", accessToken: null, assignedStore: null }, action) => {
  switch (action.type) {
    case "LOGOUT_WITH_API": {
      return { ...state, userRole: "visitor", accessToken: null }
    }
    case "LOGIN_WITH_API": {
      return { ...state, userRole: action.payload.userRole, accessToken: action.payload.accessToken, username: action.payload.username  }
    }
    case "GET_ROLE": {
      return { ...state, userRole: chooseRole(action.payload.role[0]), assignedStore: action.payload.assignedStore, userId: action.payload.id }
    }
    case "CHANGE_ROLE": {
      return { ...state, userRole: action.payload }
    }
    default: {
      return state
    }
  }
};

const chooseRole = (role) => {
  if( role === 'ROLE_SUPER_ADMIN') return 'superadmin';
  if( role === 'ROLE_DASH') return 'admin';
  else if( role === 'ROLE_STORE') return 'store';
  else if( role === 'ROLE_OPERATOR') return 'operator';
  else return 'admin';
}
