import React from "react"
import {
  Home,
  Archive,
  AlertCircle,
  Map,
  Plus,
  FileText,
  UserCheck,
  List,
  Calendar,
  User,
  Users,
  PieChart,
  Tag,
  Clock,
  ShoppingBag,
  Package,
  Lock,
  Sliders,
  Disc,
  Settings,
  UploadCloud,
  Server,
  DollarSign,
  Circle,
  Shield,
  HelpCircle,
  Activity,
  MapPin,
  Upload
} from "react-feather"

const navigationConfig = [
  {
    type: "groupHeader",
    groupTitle: "Dashboard",
    permissions: ["superadmin", "admin", "editor", "store","operator"]
  },
  {
    id: "home",
    title: "Home",
    type: "item",
    icon: <Home size={20} />,
    permissions: ["superadmin", "admin", "editor","operator"],
    navLink: "/"
  },
  {
    id: "locations",
    title: "Locations",
    type: "item",
    icon: <MapPin size={20} />,
    permissions: ["superadmin", "admin", "editor","operator"],
    navLink: "/locations"
  },
  {
    type: "groupHeader",
    groupTitle: "Office",
    permissions: ["superadmin", "admin", "editor","operator"]
  },
  {
    id: "dashboard",
    title: "Dashboard",
    type: "item",
    icon: <PieChart size={16} />,
    permissions: [],
    navLink: "/dashboard-office"
  },
  {
    id: "users",
    title: "Users",
    type: "item",
    icon: <Users size={16} />,
    permissions: ["superadmin", "admin", "editor","operator"],
    navLink: "/office-users"
  },
  {
    id: "user-groups-office",
    title: "User Groups",
    type: "item",
    icon: <Users size={16} />,
    permissions: [],
    navLink: "/user-groups-office"
  },
  {
    id: "activity",
    title: "Activity",
    type: "item",
    icon: <FileText size={16} />,
    permissions: ["superadmin", "admin", "editor","operator"],
    navLink: "/activity"
  },
  {
    id: "expiredLockers",
    title: "Expired Lockers",
    type: "item",
    icon: <Clock size={16} />,
    permissions: [],
    navLink: "/expired-lockers"
  },
  {
    type: "groupHeader",
    groupTitle: "Orders",
    permissions: []
  },
  {
    id: "dashboard-delivery",
    title: "Dashboard",
    type: "item",
    icon: <PieChart size={16} />,
    permissions: [],
    navLink: "/dashboard-delivery"
  },
  {
    id: "locations",
    title: "Locations",
    type: "item",
    icon: <Lock size={20} />,
    permissions: [],
    navLink: "/admins/lockers"
  },
  {
    id: "new-order",
    title: "New Order",
    type: "item",
    icon: <Plus size={16} />,
    permissions: [],
    navLink: "/orders/details/new"
  },
  {
    id: "orders",
    title: "Orders List",
    type: "item",
    icon: <List size={16} />,
    permissions: [],
    navLink: "/orders"
  },
  {
    id: "deposited-orders",
    title: "Deposited Orders",
    type: "item",
    icon: <Package size={16} />,
    permissions: [],
    navLink: "/deposited-orders"
  },
  {
    id: "orders-history",
    title: "Orders History",
    type: "item",
    icon: <Calendar size={16} />,
    permissions: [],
    navLink: "/orders-history"
  },
  {
    id: "archived-orders",
    title: "Archived Orders",
    type: "item",
    icon: <Archive size={16} />,
    permissions: [],
    navLink: "/archived-orders"
  },
  {
    id: "orders_logs",
    title: "Orders Logs",
    type: "item",
    icon: <FileText size={16} />,
    permissions: [],
    navLink: "/orders-logs"
  },
  {
    id: "expiredLockersDelivery",
    title: "Expired Lockers Delivery",
    type: "item",
    icon: <Clock size={16} />,
    permissions: [],
    navLink: "/expired-lockers-delivery"
  },
  {
    id: "delivery-analytics",
    title: "Delivery Analytics",
    type: "item",
    icon: <PieChart size={16} />,
    permissions: [],
    navLink: "/delivery-analytics"
  },
  {
    type: "groupHeader",
    groupTitle: "Notifications",
    permissions: ["superadmin", "admin", "editor","operator"]
  },
  {
    id: "alerts",
    title: "Alerts",
    type: "item",
    icon: <AlertCircle size={20} />,
    permissions: ["superadmin", "admin", "editor","operator"],
    navLink: "/alerts"
  },
  {
    type: "groupHeader",
    groupTitle: "Assets",
    permissions: []
  },
  {
    id: "dashboard-assets",
    title: "Dashboard",
    type: "item",
    icon: <PieChart size={16} />,
    permissions: [],
    navLink: "/dashboard-assets"
  },
  {
    id: "assets",
    title: "Assets",
    type: "item",
    icon: <Tag size={16} />,
    permissions: [],
    navLink: "/assets"
  },
  {
    id: "asset-logs",
    title: "Asset Logs",
    type: "item",
    icon: <FileText size={16} />,
    permissions: [],
    navLink: "/assets-logs"
  },
  {
    id: "assetCategories",
    title: "Asset Categories",
    type: "item",
    icon: <List size={16} />,
    permissions: [],
    navLink: "/asset-categories"
  },
  {
    id: "asset-users",
    title: "Asset Users",
    type: "item",
    icon: <User size={16} />,
    permissions: [],
    navLink: "/asset-users"
  },
  {
    id: "user-groups-assets",
    title: "User Groups",
    type: "item",
    icon: <Users size={16} />,
    permissions: [],
    navLink: "/user-groups-assets"
  },
  {
    id: "schedules",
    title: "Schedules",
    type: "item",
    icon: <Clock size={16} />,
    permissions: [],
    navLink: "/schedules"
  },
  {
    type: "groupHeader",
    groupTitle: "Marketplace",
    permissions: ["superadmin"]
  },
  {
    id: "dashboard",
    title: "Dashboard",
    type: "item",
    icon: <PieChart size={16} />,
    permissions: [],
    navLink: "/marketplace"
  },
  {
    id: "settings",
    title: "GeneralSettings",
    type: "item",
    icon: <Sliders size={16} />,
    permissions: ["superadmin", "admin"],
    navLink: "/marketplace/settings"
  },
  {
    id: "price-packs",
    title: "PricePacks",
    icon: <DollarSign size={16} />,
    permissions: [],
    type: "hidden",
    children: [
      {
        id: "visitor",
        title: "Visitor",
        type: "item",
        icon: <Circle size={10} />,
        permissions: [],
        navLink: "/marketplace/visitor"
      },
      {
        id: "guest",
        title: "Guest",
        type: "item",
        icon: <Circle size={10} />,
        permissions: [],
        navLink: "/marketplace/guest"
      },
      {
        id: "group",
        title: "Group",
        type: "item",
        icon: <Circle size={10} />,
        permissions: [],
        navLink: "/marketplace/group"
      },
      {
        id: "health",
        title: "Health",
        type: "item",
        icon: <Circle size={10} />,
        permissions: [],
        navLink: "/marketplace/health"
      },
      {
        id: "other",
        title: "Other",
        type: "item",
        icon: <Circle size={10} />,
        permissions: [],
        navLink: "/marketplace/other"
      },
    ]
  },
  {
    id: "transactions",
    title: "Transactions",
    type: "item",
    icon: <Activity size={16} />,
    permissions: [],
    navLink: "/marketplace/transactions"
  },
  {
    id: "marketplaceLogs",
    title: "Marketplace Logs",
    type: "item",
    icon: <FileText size={16} />,
    permissions: ["superadmin","admin"],
    navLink: "/marketplace/logs"
  },
  {
    type: "groupHeader",
    groupTitle: "Administration",
    permissions: ["superadmin", "admin"]
  },
  {
    id: "admins",
    title: "Admins",
    type: "item",
    icon: <UserCheck size={16} />,
    permissions: ["superadmin", "admin"],
    navLink: "/admins"
  },
  {
    id: "employees",
    title: "Employees",
    type: "hidden",
    icon: <UserCheck size={16} />,
    permissions: [],
    navLink: "/employees"
  },
  {
    id: "projects",
    title: "Projects",
    type: "hidden",
    icon: <ShoppingBag size={16} />,
    permissions: [],
    navLink: "/projects"
  },
  {
    id: "settings",
    title: "Settings",
    type: "item",
    icon: <Settings size={16} />,
    permissions: [],
    navLink: "/admins/settings"
  },
  {
    id: "adminsLogs",
    title: "Admins Logs",
    type: "item",
    icon: <FileText size={16} />,
    permissions: ["superadmin", "admin", "editor"],
    navLink: "/admins/logs"
  },
  {
    id: "adminsTools",
    title: "Admins Tools",
    type: "item",
    icon: <Sliders size={16} />,
    permissions: ["superadmin", "admin"],
    navLink: "/admins/tools"
  },
  {
    id: "adminsTerms",
    title: "Admins Terms",
    type: "item",
    icon: <Shield size={20} />,
    permissions: ["superadmin", "admin"],
    navLink: "/admins/terms"
  },
  {
    id: "adminsImporter",
    title: "Admins Importer",
    type: "item",
    icon: <Upload size={20} />,
    permissions: [],
    navLink: "/admins/importer"
  },
  {
    id: "faq",
    title: "FAQ",
    type: "item",
    icon: <HelpCircle size={16} />,
    permissions: ["superadmin"],
    navLink: "/admins/faqs"
  },
  {
    id: "newLocation",
    title: "New Location",
    type: "hidden",
    icon: <Map size={16} />,
    permissions: [],
    navLink: "/locations/config/new"
  },
  {
    id: "import-orders",
    title: "Import Orders",
    type: "hidden",
    icon: <UploadCloud size={16} />,
    permissions: [],
    navLink: "/import-orders"
  },
  {
    id: "administration",
    title: "Administration",
    icon: <Settings size={16} />,
    permissions: [],
    type: "hidden",
    children: [
      {
        id: "admins",
        title: "Admins",
        type: "hidden",
        icon: <UserCheck size={16} />,
        permissions: [],
        navLink: "/admins"
      },
      {
        id: "projects",
        title: "Projects",
        type: "hidden",
        icon: <Disc size={16} />,
        permissions: [],
        navLink: "/projects"
      },
      {
        id: "userType",
        title: "User Type",
        type: "hidden",
        icon: <Disc size={16} />,
        permissions: [],
        navLink: "/user-type"
      },
      {
        id: "service",
        title: "Service",
        type: "hidden",
        icon: <Disc size={16} />,
        permissions: [],
        navLink: "/service"
      }
    ]
  },
  {
    id: "serviceStatus",
    title: "Service Status",
    type: "hidden",
    icon: <Server size={16} />,
    permissions: [],
    navLink: "/service-status"
  },

]

export default navigationConfig
